<template>
  <ToggleTheme></ToggleTheme>

  <RouterView :key="route.path"></RouterView>

  <VueAnnouncer></VueAnnouncer>

</template>

<script setup lang="ts">
    import { RouterView, useRoute } from 'vue-router';
    import ToggleTheme from './components/ToggleTheme.vue'

    const route = useRoute();
</script>
