/**
 * Get data from Local Storage
 *
 * @version 1.0.0
 * @param key The name of the local storage key to retrieve from
 * @returns Local storage data for the key
 * @example
 *
 * getLocalStorageItem(key);
 * // => Value
 */
const getLocalStorageItem = (key: string): string => {
    const item = localStorage.getItem(key);

    return (item)
        ? JSON.parse(item)
        : null;
};

export default getLocalStorageItem;
