import { createApp } from 'vue';
import VueAnnouncer from '@vue-a11y/announcer';
import { createHead } from '@unhead/vue';
import App from './App.vue';
import router from './router';

const head = createHead();

createApp(App)
    .use(VueAnnouncer)
    .use(router)
    .use(head)
    .mount('[data-vue-app]');
