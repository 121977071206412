/**
 * Set data in Local Storage
 *
 * @version 1.0.0
 * @param key The name of the local storage key to be set
 * @param item The item to be set in local storage
 * @example
 *
 * setLocalStorageItem(key, item);
 */
const setLocalStorageItem = (key: string, item: string) => {
    const stringifyItem = JSON.stringify(item);

    localStorage.setItem(key, stringifyItem);
};

export default setLocalStorageItem;
